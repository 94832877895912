import React from "react";

const Steppers = ({ activeStep = 2 }) => {
  const steps = [
    {
      id: 1,
      title: "Create New Item",
      description: "This is the process of minting your NFT.",
    },
    {
      id: 2,
      title: "Sale NFT",
      description: "This is the process of creating an on-sale NFT.",
    },
  ];

  return (
    <div className="flex items-center gap-4 mt-20 w-full relative">
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          {/* Step Box */}
          <div
            className={`flex flex-1 items-center gap-4 border p-5 ${
              activeStep === step.id
                ? "border-pink-500"
                : activeStep > step.id
                ? "border-green-500"
                : "border-gray-300"
            }`}
            style={{ border: "1px solid #e3e3e3" }}
          >
            <div
              className={`w-20 h-20 flex items-center justify-center rounded-full ${
                activeStep === step.id
                  ? "bg-pink-500 text-white"
                  : activeStep > step.id
                  ? "bg-green-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              {activeStep > step.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              ) : (
                <h2 className="text-4xl">{step.id}</h2>
              )}
            </div>
            <div>
              <h2 className="text-3xl font-bold">{step.title}</h2>
              <p className="text-lg text-gray-600">{step.description}</p>
            </div>
          </div>

          {/* Connecting Line */}
          {index < steps.length - 1 && (
            <div
              className={`w-[10%] h-1 ${
                activeStep > step.id ? "bg-green-500" : "bg-gray-300"
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Steppers;
