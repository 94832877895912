export async function switchNetwork(networkId) {
	try {
	  console.log("Switching to network:", networkId);
  
	  // Ensure the user has connected their wallet
	  await window.ethereum.request({ method: "eth_requestAccounts" });
  
	  // Convert networkId to hexadecimal format
	  const chainIdHex = `0x${parseInt(networkId, 10).toString(16)}`;
  
	  // Attempt to switch the network
	  await window.ethereum.request({
		method: "wallet_switchEthereumChain",
		params: [{ chainId: chainIdHex }],
	  });
  
	} catch (error) {
	  if (error.code === 4902) {
		console.error(
		  `Network ${networkId} is not available in MetaMask. Adding it now...`
		);
  
		// Add the chain to MetaMask if it is not recognized
		try {
		  const chainParams = getChainParams(networkId);
		  await window.ethereum.request({
			method: "wallet_addEthereumChain",
			params: [chainParams],
		  });
		} catch (addError) {
		  console.error("Failed to add the network:", addError);
		}
	  } else {
		console.error("Error switching network:", error);
	  }
	}
  }
  
  // Helper function to define chain parameters
  function getChainParams(networkId) {
	const chains = {
	  56: {
		chainId: "0x38",
		chainName: "Binance Smart Chain Mainnet",
		nativeCurrency: {
		  name: "Binance Coin",
		  symbol: "BNB",
		  decimals: 18,
		},
		rpcUrls: ["https://bsc-dataseed1.binance.org/"],
		blockExplorerUrls: ["https://bscscan.com"],
	  },
  
	  // Ethereum Mainnet
	  1: {
		chainId: "0x1",
		chainName: "Ethereum Mainnet",
		nativeCurrency: {
		  name: "Ether",
		  symbol: "ETH",
		  decimals: 18,
		},
		rpcUrls: ["https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID"], // Replace with your Infura Project ID
		blockExplorerUrls: ["https://etherscan.io"],
	  },
  
	  // Polygon Mainnet
	  137: {
		chainId: "0x89",
		chainName: "Polygon Mainnet",
		nativeCurrency: {
		  name: "POL",
		  symbol: "POL",
		  decimals: 18,
		},
		rpcUrls: ["https://polygon-rpc.com/"],
		blockExplorerUrls: ["https://polygonscan.com"],
	  },
	};
  
	if (!chains[networkId]) {
	  throw new Error(`Unsupported network ID: ${networkId}`);
	}
  
	return chains[networkId];
  }
  
  