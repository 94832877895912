import BNB from "../assets/image/bnb.png";
import ETH from "../assets/image/eth.png";
import MATIC from "../assets/image/plolygon.png";

const ChainCheck = ({ itemValue }) => {
  const chainData = {
    BNB: { image: BNB, title: "Binance" },
    ETH: { image: ETH, title: "Ethereum" },
    POL: { image: MATIC, title: "Polygon" },
    MATIC: { image: MATIC, title: "Polygon" },

  };

  const { image, title } = chainData[itemValue] || {};

  return (
    <div
      className="shadow-3xl absolute rounded-full bottom-4 right-4"
      title={title}
    >
      <img
        src={image}
        alt={title}
        className="rounded-full w-8 h-8" 
      />
    </div>
  );
};

export default ChainCheck;
