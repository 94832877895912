import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  setLoginState,
  setProfileData,
  setWalletInfo,
} from "../services/slices/constants";
import {
  useConnectMutation,
  useDisconnectMutation,
  useGetProfileQuery,
} from "../services/apis";
import metamask from "../assets/image/download.png";
import polygon from "../assets/image/plolygon.png";
import hashs from "../assets/image/hashs.png";
import bnb from "../assets/image/bnb.png";
import eth from "../assets/image/eth.png";
import BlockSwitch from "./BlockSwitch";
import Logo from "../assets/image/logo.png";
import { useLocation } from "react-router-dom";
import AVATAR from "../assets/image/x.png";
import { useMarketplaceQuery, useListCategoryQuery } from "../services/apis";
import { PopUp } from "../utils/alert";
import { TOKEN_NAME, USD } from "../utils/constants";
import Maintenance from "./Maintenance";
import { encryptData } from "../utils/encrypto";
import useWalletBalances from "../hooks/useWalletBalances";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginState = useSelector((state) => state?.constants?.loginState);
  const walletInfo = useSelector((state) => state?.constants?.walletInfo);
  const profileData = useSelector((state) => state.constants.profileData);
  const chainType = useSelector((state) => state.constants.chainType);
  const { coinBalance, tokenBalance } = useWalletBalances(
    chainType?.wethAddress
  );

  const [connect, { data, isLoading, isSuccess }] = useConnectMutation();
  const { data: CollectionData, refetch: collectionRefetch } =
    useListCategoryQuery();
  const { data: profileInfo, refetch: profileReload } = useGetProfileQuery(
    profileData?.data?._id
  );

  const [isShow, setIsShow] = useState(false);
  const [isShowMobile, setIsShowMobile] = useState(false);
  const dispatch = useDispatch();
  const [roleStatus, setRoleStatus] = useState("");
  const [copy, setCopy] = useState(false);
  const [list, setList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [logShow, setLogShow] = useState(false);
  const [walletShow, setWalletShow] = useState(false);
  const [search, setSearch] = useState("");
  const [isKnow, setIsKnow] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const { data: nftList, refetch } = useMarketplaceQuery();
  const [balance, setBalance] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const getNativeBalance = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await provider.listAccounts();
        const balance = await provider.getBalance(accounts[0]);
        setBalance(ethers.utils.formatEther(balance));
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLoggedIn");
    refetch();
    collectionRefetch();
    hooksCall();
    changeNetwork();
    profileReload();
    if (loggedIn === "true") {
      getNativeBalance();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const hooksCall = async () => {
    window.ethereum.on("accountsChanged", async (accounts) => {
      dispatch(setLoginState(0));
    });
  };

  const changeNetwork = () => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", async (accounts) => {
        getbalance(walletInfo?.address, Number(accounts));
        getNativeBalance();
      });
    }
  };

  const getbalance = async (walletAdress, chainId) => {
    await window.ethereum
      .request({
        method: "eth_getBalance",
        params: [walletAdress, "latest"],
      })
      .then((balance) => {
        dispatch(
          setWalletInfo({
            address: walletAdress,
            balance: ethers.utils.formatEther(balance),
            chainId,
          })
        );
      });
  };

  const accountChangehandleUserConnect = async (account) => {
    getbalance(account);
    navigate("/");
  };

  useEffect(() => {
    setList(nftList?.data);
    setCollectionList();
  }, [nftList]);

  useEffect(() => {
    setCollectionList(CollectionData?.data);
  }, [CollectionData]);

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        dispatch(setLoginState(0));
        navigate("/");
      }
    };

    const provider = window.ethereum;
    if (provider) {
      provider.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (provider) {
        provider.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (data?.success) {
      sessionStorage?.setItem("isLoggedIn", "true");
      if (roleStatus === "user") {
        if (roleStatus !== data?.data?.role) {
          PopUp(
            "Wrong Role Detected",
            "Please check your metamask account",
            "error"
          );
          dispatch(setLoginState(0));
          return navigate("/");
        }
      }

      if (roleStatus === "creator") {
        if (data?.data?.role === "user") {
          PopUp(
            "Wrong Role Detected",
            "Please check your metamask account",
            "error"
          );
          dispatch(setLoginState(0));
          return navigate("/");
        }
      }

      dispatch(setProfileData(data));
      dispatch(setLoginState(1));

      const encryptToken = encryptData(data?.accessToken);

      try {
        if (data?.data?.role === "user") {
          sessionStorage.setItem("token", encryptToken);
          return navigate("/profile");
        }

        if (data?.data?.role === "admin") {
          sessionStorage.setItem("token", encryptToken);
          return navigate("/admin");
        }

        if (data?.data?.role === "creator") {
          sessionStorage.setItem("token", encryptToken);
          return navigate("/admin");
        }
      } catch (erro) {
        console.log(erro);
      }
    }
  }, [data]);

  const searchData = (searchTerm) => {
    if (!searchTerm) {
      return list;
    }

    const lowerCaseSearch = searchTerm.toLowerCase();
    const filteredList = nftList?.data?.reduce((accumulator, obj) => {
      if (obj.nft_name.toLowerCase().includes(lowerCaseSearch)) {
        accumulator.push(obj);
      }
      return accumulator;
    }, []);

    return setList(filteredList);
  };

  const searchDataCollection = (searchTerm) => {
    if (!searchTerm) {
      return collectionList;
    }

    const lowerCaseSearch = searchTerm.toLowerCase();
    const filteredList = CollectionData?.data?.reduce((accumulator, obj) => {
      if (obj.name.toLowerCase().includes(lowerCaseSearch)) {
        accumulator.push(obj);
      }
      return accumulator;
    }, []);

    return setCollectionList(filteredList);
  };

  useEffect(() => {
    setTimeout(() => {
      searchData();
      searchDataCollection();
    }, 500);

    if (search === "") {
      setList(nftList?.data);
    }
  }, [search]);

  const connectToWallet = async (role) => {
    setRoleStatus(role);
    if (!window.ethereum) {
      PopUp("Please install metamask", "", "error");
      return;
    }
    if (window.ethereum && window.ethereum !== "undefined") {
      setLoginState(false);
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((chainId) => {
          chainId = parseInt(chainId, 16);
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => {
              if (res[0]) {
                accountChangehandleUserConnect(res[0]);
                getbalance(res[0], chainId);
                connect({
                  wallet_address: res[0],
                  role,
                });
              } else {
                alert("Please connect to metamask or restart your browser.");
              }
            });
        })
        .catch((err) => {
          console.error("Error getting chainId:", err);
        });
    } else {
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      // Check if Metamask is connected
      window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
        if (accounts.length === 0) {
          dispatch(setLoginState(0));
        }
      });
    }
  }, []);

  return (
    <>
      <div className="relative w-full">
        <header
          className="header bg-white shadow-sm"
          style={{ padding: "5px 0" }}
        >
          <div className="flex items-center ">
            <Link className="" to="/">
              <img
                src={Logo}
                alt=""
                style={{ width: "130px", padding: "5px" }}
              />
            </Link>
            <div className="w-1 h-20 bg-gray-100"></div>
            <div className="mx-6 menuMob ">
              <Link
                to="/marketplace"
                className="text-2xl font-bold text-gray-700 txt2 "
              >
                Marketplace
              </Link>

              <Link
                to="/interests"
                className="text-2xl font-bold text-gray-700 ml-12 txt2 "
              >
                Interests
              </Link>

              <span
                className="text-2xl  font-bold text-gray-700 ml-12 relative cursor-pointer txt2"
                onClick={() => setIsKnow(!isKnow)}
              >
                Explore<i class="fa-solid fa-angle-down"></i>
                {isKnow && (
                  <div
                    style={{ width: "max-content" }}
                    className="mainBorder gap-4 p-4 bg-white flex flex-col absolute left-0 top-[200%] width-full"
                  >
                    <Link
                      to="/why-us"
                      className="text-2xl font-bold text-gray-700 txt2"
                    >
                      Why Artequitys
                    </Link>
                    <Link
                      to="/auction-house"
                      className="text-2xl font-bold text-gray-700 txt2 "
                    >
                      Auction House
                    </Link>
                    <Link
                      to="/working"
                      className="text-2xl font-bold text-gray-700 txt2"
                    >
                      How It Works
                    </Link>

                    <Link
                      to="/why-invest-in-art"
                      className="text-2xl font-bold text-gray-700 txt2"
                    >
                      Why Invest in Arts
                    </Link>
                    <Link
                      to="/art-swap"
                      className="text-2xl font-bold text-gray-700 txt2"
                    >
                      Trade
                    </Link>
                  </div>
                )}
              </span>
            </div>

            <div
              style={{ position: "relative" }}
              onClick={() => setIsSearch(!isSearch)}
            >
              <div
                className={`txt2  cursor-pointer text-2xl font-bold text-gray-700 mx-6 flex items-center ${
                  isSearch ? "text-second" : "text-gray-700"
                }`}
              >
                <i class="fa-solid fa-magnifying-glass mr-3"></i>
                Search{" "}
                {isSearch && <i class="fa-solid fa-xmark ml-8 text-3xl"></i>}
              </div>
            </div>
          </div>
          <div className="mr-16">
            <ul>
              <div className="mt-1 mr-8">
                <BlockSwitch />
              </div>
              {loginState === 1 && (
                <div className=" flex items-center p-4 gap-4 mr-8 mainBorder">
                  <div>
                    <i class="fa-solid fa-wallet text-2xl"></i>
                  </div>
                  <div className="flex text-2xl">
                    <span>
                      {isNaN(coinBalance) ||
                      coinBalance === undefined ||
                      coinBalance === null
                        ? "0"
                        : parseFloat(coinBalance.toFixed(3)).toString()}{" "}
                      {chainType?.name}
                    </span>
                    <div className="mx-4 w-1 bg-gray-200"></div>
                    <span>
                      {isNaN(tokenBalance) ||
                      tokenBalance === undefined ||
                      tokenBalance === null
                        ? "0"
                        : parseFloat(tokenBalance.toFixed(3)).toString()}{" "}
                      HASHS
                    </span>
                  </div>
                </div>
              )}

              {loginState === 1 ? (
                <>
                  <div className="flex items-center">
                    <div
                      className="relative cursor-pointer"
                      onClick={() => setIsInfo(!isInfo)}
                    >
                      <img
                        src={
                          profileInfo?.data?.display_picture
                            ? `${profileInfo?.data?.display_picture}`
                            : AVATAR
                        }
                        alt=""
                        className="w-16 h-16 "
                      />

                      {isInfo && (
                        <span
                          className="absolute mainBorder top-[125%] bg-white flex flex-col p-4 gap-4 text-2xl right-[0px]"
                          style={{ width: "max-content" }}
                        >
                          {" "}
                          <Link
                            to={
                              profileData?.data?.role === "creator" ||
                              profileData?.data?.role === "admin"
                                ? "/admin"
                                : "/profile"
                            }
                          >
                            <span
                              className="text-first txt2 cursor-pointer"
                              onClick={() => setLogShow(true)}
                            >
                              My Profile
                            </span>
                          </Link>
                          <span
                            className="text-first txt2 cursor-pointer"
                            onClick={() => setIsShow(true)}
                          >
                            Wallet
                          </span>
                          <span
                            className="text-first txt2 cursor-pointer"
                            onClick={() => setLogShow(true)}
                          >
                            Logout
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <li onClick={() => setWalletShow(true)}>
                  <button
                    className="flex items-center gap-3 mainBorder bg-gray-100"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "0px",
                      color: "black",
                    }}
                  >
                    <img src={metamask} alt="" className="w-8" />
                    {isLoading ? "Connecting..." : "CONNECT"}
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="menu" onClick={() => setIsShowMobile(true)}>
            <label htmlFor="chk1">
              <i className="fa fa-bars" />
            </label>
          </div>
        </header>
        {isSearch && (
          <div
            className="w-full site-container fixed bg-white mainBorder shadow-lg"
            style={{
              textAlign: "left",
              left: "50%",
              top: "9%",
              zIndex: "999",
              margin: "0 auto",
              maxHeight: "70vh",
              overflowY: "auto",
              transform: "translate(-50%, 0%)",
            }}
          >
            <div className="m-5" style={{ width: "98%" }}>
              <input
                type="text"
                placeholder="Search NFT and Artist"
                value={search}
                className="w-full p-3 bg-gray-50  text-2xl"
                onChange={(e) => {
                  setSearch(e.target.value);
                  searchData(e.target.value);
                  searchDataCollection(e.target.value);
                }}
              />
            </div>
            {search && (
              <>
                {list?.length === 0 && collectionList?.length === 0 && (
                  <div className="text-2xl ml-4 text-center p-6">
                    No Items Found
                  </div>
                )}
                {list?.length !== 0 && (
                  <h2 className="text-3xl font-bold text-left px-6 bannerText mt-8">
                    NFTs
                  </h2>
                )}
                {list?.length > 0 &&
                  list?.map((item) => {
                    return (
                      <Link to={`/product/${item?._id}`}>
                        <div className="flex items-center hover:bg-gray-100 p-6">
                          <img
                            src={item?.nft_media[0]}
                            style={{
                              height: "40px",
                              width: "40px",
                            }}
                            alt
                            className="hover:opacity-1"
                          />
                          <h5 className="text-2xl ml-4 hover:bg-sky-700">
                            {item?.nft_name}
                          </h5>
                        </div>
                      </Link>
                    );
                  })}
                {collectionList?.length !== 0 && (
                  <h2 className="text-3xl font-bold text-left px-6 bannerText mt-8">
                    Collection
                  </h2>
                )}
                {collectionList?.length > 0 &&
                  collectionList?.map((item) => {
                    return (
                      <Link to={`/collection/${item?._id}`}>
                        <div className="flex items-center hover:bg-gray-100 p-6">
                          <img
                            src={item?.image}
                            style={{
                              height: "40px",
                              width: "40px",
                            }}
                            alt
                          />
                          <h5 className="text-2xl ml-4">{item?.name}</h5>
                        </div>
                      </Link>
                    );
                  })}
              </>
            )}
          </div>
        )}
      </div>

      <Modal show={isShow} onClose={() => setIsShow(false)} title="Wallet Info">
        <div
          className="flex items-center p-8 justify-center flex-col"
          style={{ border: "1px solid #e3e3e3" }}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-8">
              <div>
                <img src={metamask} alt="" className="w-20 h-20" />
              </div>
              <div>
                <h3 className="font-bold text-3xl bannerText">Polygon</h3>
                <div className="mt-2 text-gray-700 text-xl  ">
                  <span>
                    {walletInfo?.address?.substring(0, 5) +
                      "..." +
                      walletInfo?.address?.slice(-5)}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <CopyToClipboard
                text={walletInfo?.address}
                onCopy={() => setCopy(true)}
              >
                <button className="text-3xl w-20 h-20 bg-gray-100 text-gray-700  ml-6">
                  {copy ? (
                    <i class="fa-solid fa-clone text-pink-700"></i>
                  ) : (
                    <i class="fa-solid fa-clone"></i>
                  )}
                </button>
              </CopyToClipboard>
              <button className="text-3xl w-20 h-20 bg-gray-100 text-gray-700  ml-6">
                <i class="fa-solid fa-right-from-bracket"></i>
              </button>
            </div>
          </div>
          <div
            className="w-full mt-16  rounded p-6 flex flex-col gap-8 "
            style={{ border: "1px solid #e3e3e3" }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={eth} alt="" className="w-10 h-10" />
                <span className="text-2xl ">
                  {walletInfo.chainId === 1
                    ? parseFloat(balance).toFixed(5)
                    : "0"}{" "}
                  ETH
                </span>
              </div>
              <h2 className="text-2xl text-gray-400">$ 0.0</h2>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={bnb} alt="" className="w-10 h-10" />
                <span className="text-2xl">
                  {walletInfo.chainId === 56
                    ? parseFloat(balance).toFixed(5)
                    : "0"}{" "}
                  BNB
                </span>
              </div>
              <h2 className="text-2xl text-gray-400">$ 0.0</h2>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={polygon} alt="" className="w-10 h-10" />
                <span className="text-2xl">
                  {walletInfo.chainId === 137
                    ? parseFloat(balance).toFixed(3)
                    : "0"}{" "}
                  POL
                </span>
              </div>
              <h2 className="text-2xl text-gray-400">$ 0.0</h2>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={hashs} alt="" className="w-10 h-10" />
                <span className="text-2xl">
                  {tokenBalance ? tokenBalance : "0"} {TOKEN_NAME}
                </span>
              </div>
              <h2 className="text-2xl text-gray-400">$ 0.0</h2>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={isShowMobile}
        onClose={() => setIsShowMobile(false)}
        title="Menu"
      >
        <div className="flex items-start pt-0 justify-start flex-col">
          <div className="flex flex-col text-left gap-4">
            {[
              { id: 1, title: "Home", link: "/" },
              { id: 2, title: "Marketplace", link: "/marketplace" },
              { id: 3, title: "Artists", link: "/intersts" },
              { id: 4, title: "Auction", link: "/auction-house" },

              { id: 5, title: "How it Works", link: "/working" },
              {
                id: 5,
                title: "Why Invest in Arts",
                link: "/why-invest-in-art",
              },

              { id: 6, title: "why Artequitys", link: "/why-us" },
              { id: 7, title: "Trade", link: "/art-swap" },
            ].map((item) => {
              return (
                <Link to={`${item?.link}`} key={item?.id}>
                  <span className="text-4xl font-bold bannerText text-first txt2 ">
                    {item?.title}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </Modal>

      <Modal
        show={logShow}
        onClose={() => setLogShow(false)}
        title="Are you sure you want to logout?"
      >
        <div className="">
          <div className="flex items-center gap-6 mt-10">
            <button
              className="profile-btn2 w-full flex-1"
              onClick={() => setLogShow(false)}
            >
              Cancel
            </button>
            <button
              className="profile-btn w-full flex-1"
              onClick={() => {
                setLogShow(false);
                dispatch(setLoginState(0));
                navigate("/");
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={walletShow}
        onClose={() => setWalletShow(false)}
        title="Before Wallet Connection, Please Choose your role"
      >
        <div className="">
          <div className="flex flex-col text-center mt-6 mb-6">
            <p className="text-2xl text-gray-600">
              Artequitys makes a clear distinction between our Creators and our
              Users. Therefore, to create new NFT content, you must first
              connect your Creator Digital Wallet to begin the Registration
              process. If you wish to join as a Buyer, please connect your
              wallet to begin engaging with the platform.
            </p>
          </div>
          <div className="mt-16 flex items-center gap-6">
            <button
              className="profile-btn2 flex-1"
              onClick={() => {
                connectToWallet("creator");
                setWalletShow(false);
              }}
            >
              SELLER
            </button>
            <button
              className="profile-btn flex-1"
              onClick={() => {
                connectToWallet("user");
                setWalletShow(false);
              }}
            >
              BUYER
            </button>
          </div>
        </div>
      </Modal>

      {/* <Modal show={isError} onClose={() => setWalletShow(false)}>
        <Maintenance />
      </Modal> */}
    </>
  );
};

export default Navbar;
