import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDisableNftMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import ChainCheck from "./ChainCheck";

const Card = ({ item, url, sale, sold, reload, itemData }) => {
  const navigate = useNavigate();
  const [list, setList] = useState({});

  const [disableNft, { data }] = useDisableNftMutation();

  const handleHide = (id, visible) => {
    disableNft({
      id: id,
      is_visible: visible === 1 ? 0 : visible === 0 ? 1 : 0,
    });
  };

  useEffect(() => {
    setList(item);
  }, [item]);

  const handleCall = async () => {
    if (data?.success) {
      await PopUp(
        `NFT is ${
          list?.is_visible === 1 ? "disabled" : "enabled"
        } successfully`,
        `${
          list?.is_visible === 1 ? "No one" : "everyone"
        } can see your NFT on marketplace`,
        "success"
      );
      setList((prevState) => ({
        ...prevState,
        is_visible: prevState.is_visible === 1 ? 0 : 1,
      }));
    }
  };

  useEffect(() => {
    handleCall();
  }, [data]);

  return (
    <>
      <div className="shadow-card">
        <div>
          <div className="relative">
            <Link to={list.hasOwnProperty("token_owner") ? "" : url}>
              <img
                src={list?.nft_media && list?.nft_media[0]}
                alt=""
                className="w-full imgs"
                style={{ height: "200px", objectFit: "cover" }}
              />
            </Link>
            <ChainCheck itemValue={list?.blockchain} />
          </div>

          <div>
            {sale && (
              <div
                className=" flex items-center justify-between my-8"
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBottom: "20px",
                }}
              >
                <label htmlFor="" className="text-2xl font-bold">
                  Hide NFT
                </label>

                <label className="label-toggle">
                  <input
                    className="input-toggle"
                    type="checkbox"
                    checked={
                      list?.is_visible === 1
                        ? false
                        : list?.is_visible === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleHide(list?._id, list?.is_visible);
                    }}
                    disabled={itemData?.deligation}
                  />
                  <span className="span-toggle" />
                </label>
              </div>
            )}
            <Link to={list.hasOwnProperty("token_owner") ? "" : url}>
              <h2 className="text-3xl bannerText txt font-bold mb-6 mt-6">
                {list?.nft_name}
              </h2>
            </Link>

            {!sale ? (
              <button
                className={` font-bold mx-auto px-8 py-2 ${
                  list.hasOwnProperty("token_owner")
                    ? "bg-gray-100 text-black"
                    : "bg-first text-white"
                }  `}
                style={{
                  fontSize: "14px",
                }}
                onClick={() => navigate(url)}
                disabled={list.hasOwnProperty("token_owner") ? true : false}
              >
                {list.hasOwnProperty("token_owner")
                  ? "Already on Sale"
                  : "Sale NFT"}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
