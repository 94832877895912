//Polygon
export const WETHTokenAddress = "0xE410d33FeD4593Aa075974bc4A351aE7215E0C63";  //using as hashs token on polygon mainnet
export const collectionAddress = "0x6a061C2A298C2CBBb725B16fF298aCEF7f61D21d"; //using for Polygon Mainnet
export const fractionAddress = "0x25752410314537f34CF0BdC2FDbd6c03568D9Ea8";   //using for polygon mainnet
export const admin = "0x9814d19Ff2f9fc2954219b7405963b6084091082"; //will be use when Hasha minting will enable

// EThereum
export const WETHTokenAddressETH = "0xE410d33FeD4593Aa075974bc4A351aE7215E0C63"; //using for hashs token on Etehreum mainnet
export const collectionAddressETH = "0x70B393f079229e9a5C18C9600C166B1eAba08F89"; //using for ethereum mainnet
export const fractionAddressETH = "0xb90223B33596176d65E9Fef8AFD53eBE075d8b23"; //using for ethereum mainnet

// Binance
export const WETHTokenAddressBNB = "0xfAe44dDfCc3D5d0Be78eF52ccBa17ECa5C486409";  //using for Binance mainnet
export const collectionAddressBNB = "0x98D204B67197ab96d895179B3723b98bC434572a"; //using for Binnace mainnet
export const fractionAddressBNB = "0x09dc7b8D9b110e7d38b4f8b304A52E8c3f7bE914"; //using for Binnace mainnet
