import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import IMG from "../assets/image/user-dummy.png";
import {
  useEditProfileMutation,
  useGetProfileQuery,
  useSendOtpMutation,
  useVerifyOtpMutation,
} from "../services/apis";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PopUp } from "../utils/alert";
import { useDispatch } from "react-redux";
import { setProfileData } from "../services/slices/constants";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editProfile, { data, isLoading }] = useEditProfileMutation();
  const [sendOtp, { data: otpData, isLoading: otpLoading, isError, error }] =
    useSendOtpMutation();

  const [
    verifyOtp,
    {
      data: verifyData,
      isLoading: verifyLoading,
      isError: verifyErr,
      error: verifyError,
    },
  ] = useVerifyOtpMutation();

  const profileData = useSelector((state) => state.constants.profileData);
  const { data: profileInfo, refetch } = useGetProfileQuery(id);

  useEffect(() => {
    refetch();
  }, [id]);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [occupation, setOccupation] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const handleImage = (event) => {
    const newFile = event.target.files[0];
    setImage(newFile);
    setImageUrl(URL.createObjectURL(newFile));
  };

  useEffect(() => {
    setName(profileInfo?.data?.name);
    setEmail(profileInfo?.data?.email);
    setOccupation(profileInfo?.data?.occupation);
    setBio(profileInfo?.data?.bio);
    setImageUrl(profileInfo?.data?.display_picture);
    setPhone(profileInfo?.data?.phone_no);
    setLink(profileInfo?.data?.link);
  }, [profileInfo]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("phone_no", phone);
    formData.append("name", name);
    formData.append("display_picture", image);
    formData.append("bio", bio);
    formData.append("email", email);
    formData.append("link", link);
    formData.append("occupation", occupation);
    editProfile(formData);
  };

  useEffect(() => {
    if (data?.success) {
      dispatch(setProfileData(data));
      PopUp("Profile edit successfully", "", "success");
      if (profileData?.data?.role === "admin") {
        navigate("/admin");
      }

      if (profileData?.data?.role === "user") {
        navigate("/profile");
      }
    }
    if (data?.success === false) {
      PopUp("Something went wrong", " ", "error");
    }
  }, [data]);

  useEffect(() => {
    if (otpData?.success) {
      PopUp("Otp sent to your email", "please check your inbox", "success");
      setOtpStatus(true);
    }
    if (isError) {
      PopUp("Something went wrong", error?.data?.message, "error");
      return;
    }
  }, [otpData, isError, error]);

  useEffect(() => {
    if (verifyData?.success) {
      PopUp(
        "Email is verified successfully",
        "please submit your profile",
        "success"
      );
      setOtpStatus(false);
    }
    if (verifyErr) {
      PopUp("Something went wrong", verifyError?.data?.message, "error");
      return;
    }
  }, [verifyData, verifyError, verifyErr]);

  const sendOTP = (e) => {
    e.preventDefault();
    sendOtp({ email });
  };

  const verifyOTP = (e) => {
    e.preventDefault();
    verifyOtp({ email, otp });
  };

  return (
    <>
      <Navbar />
      <section className="bg-white mt-28 ">
        <div className="profile-container">
          <h2 className="text-6xl text-bold text-center pt-20 pb-16 font-bold bannerText">
            Profile Details
          </h2>

          <div className="form-box">
            <div className="w-[200px] h-[200px] cat-img">
              <img
                src={imageUrl || IMG}
                alt=""
                className="w-full rounded-xl"
                style={{ objectFit: "cover" }}
              />

              <label class="input label">
                <input type="file" required onChange={handleImage} />
                <span>
                  <i class="fa-solid fa-upload text-2xl text-white"></i>
                </span>
              </label>
            </div>
          </div>
          <form action="">
            <div className="form-box">
              <label htmlFor="">Address</label>
              <input
                type="text"
                value={profileData?.data?.wallet_address}
                disabled
              />
            </div>
            <div className="form-box">
              <label htmlFor="">Display Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-box">
              <label htmlFor="">Bio</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></textarea>
            </div>
            <div className="form-box">
              <label htmlFor="">Email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="flex items-center gap-5">
                <button
                  className="bg-green-600 px-3 py-1 rounded-lg text-white text-xl"
                  style={{ width: "max-content" }}
                  onClick={sendOTP}
                >
                  {otpLoading ? "Loading.." : "verify email"}
                </button>
                {profileData?.data?.is_verified === 1 && (
                  <p className="text-xl text-green-600">
                    Your email is verified
                  </p>
                )}
              </div>
            </div>

            {otpStatus && (
              <div className="form-box mt-6">
                <label htmlFor="">Enter your OTP here</label>
                <div>
                  <input
                    type="tel"
                    maxlength="6"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <button
                    className="bg-green-600 px-3 py-1 rounded text-white text-xl"
                    style={{ width: "max-content" }}
                    onClick={verifyOTP}
                  >
                    {verifyLoading ? "Loading.." : "Confirm OTP"}
                  </button>
                </div>
              </div>
            )}

            <div className="form-box">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-box">
              <label htmlFor="">Occupation</label>
              <input
                type="text"
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
            </div>

            <div className="form-box">
              <label htmlFor="">Website Link</label>
              <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
          </form>
          <div className="form-button flex gap-6">
            <button className="profile-btn2 flex-1">Cancel</button>
            <button className="profile-btn flex-1" onClick={handleSubmit}>
              {isLoading ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProfile;
