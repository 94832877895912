import { useState, useEffect } from "react";
import { ethers } from "ethers";

const useWalletBalances = (tokenAddress) => {
  const [coinBalance, setCoinBalance] = useState(null); // Native coin balance (ETH, BNB, etc.)
  const [tokenBalance, setTokenBalance] = useState(null); // Token balance (ERC-20)

  useEffect(() => {
    const getBalances = async () => {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.send("eth_requestAccounts", []);
          const address = accounts[0];

          // Fetch native coin balance (ETH, BNB, etc.)
          const balanceWei = await provider.getBalance(address);
          const formattedCoinBalance = ethers.utils.formatEther(balanceWei);
          setCoinBalance(Number(formattedCoinBalance));

          // Fetch token balance if tokenAddress is provided
          if (tokenAddress) {
            const tokenContract = new ethers.Contract(
              tokenAddress,
              [
                "function balanceOf(address) view returns (uint256)",
                "function decimals() view returns (uint8)",
              ],
              provider
            );

            const tokenBalanceRaw = await tokenContract.balanceOf(address);
            const tokenDecimals = await tokenContract.decimals();
            const formattedTokenBalance = ethers.utils.formatUnits(
              tokenBalanceRaw,
              tokenDecimals
            );
            setTokenBalance(Number(formattedTokenBalance));
          }
        } catch (error) {
          console.error("Error fetching balances:", error);
        }
      } else {
        console.error("MetaMask is not installed!");
      }
    };

    const loggedIn = sessionStorage.getItem("isLoggedIn");

    if (loggedIn === "true") {
      getBalances();
    }


    // Update balances on account or chain change
    const handleEvents = () => getBalances();

    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleEvents);
      window.ethereum.on("accountsChanged", handleEvents);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", handleEvents);
        window.ethereum.removeListener("accountsChanged", handleEvents);
      }
    };
  }, [tokenAddress]);

  return { coinBalance, tokenBalance };
};

export default useWalletBalances;
