import React, { useEffect } from "react";
import TREND_1 from "../assets/image/t1.png";

import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

import { useListCategoryQuery } from "../services/apis";

import "@splidejs/react-splide/css";
// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
// or only core styles
import "@splidejs/react-splide/css/core";
import { Link } from "react-router-dom";
import HomeHeading from "./HomeHeading";

const TrendingCollection = () => {
  const { data: listCat, refetch: listLoad } = useListCategoryQuery();

  useEffect(() => {
    listLoad();
  }, []);
  return (
    <div className="p-10">
      <section className="review mb-20" id="review">
        <HomeHeading title="Browse By Interests" description="" />
        <div className="swiper review-slider site-container ">
          <div className="swiper-wrapper">
            <Splide
              hasTrack={false}
              options={{
                rewind: true,
                width: "100%",
                type: "slide",
                padding: "0rem",
                gap: "2rem",
                perPage: 6,
                arrows: true,
                pagination: false,
                breakpoints: {
                  580: {
                    perPage: 1,
                  },
                  720: {
                    perPage: 4,
                  },
                  1080: {
                    perPage: 3,
                  },
                },
              }}
            >
              <SplideTrack>
                {listCat?.data?.length > 0 &&
                  listCat?.data?.map((item) => {
                    return (
                      <SplideSlide>
                        <div
                          className="swiper-slide box mt-20 shadow"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <Link to={`/collection/${item?._id}`}>
                            {item?.image && (
                              <img
                                src={`${item?.image}`}
                                alt
                                className="imgs"
                                style={{ height: "200px", objectFit: "cover" }}
                              />
                            )}

                            <h3
                              className="text-4xl bg-pink-100 bannerText font-bold txt "
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "17px",
                                padding: ".5em",
                              }}
                            >
                              {item?.name?.length > 30
                                ? item?.name?.slice(0, 30) + "..."
                                : item?.name}
                            </h3>
                            {/* <div className="text-left mt-2 uppercase text-xl text-gray-500">
                              {item?.country} -{" "}
                              <span className="text-first">{item?.dob}</span>
                            </div> */}
                          </Link>
                        </div>
                      </SplideSlide>
                    );
                  })}
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrendingCollection;
