import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import { useListCategoryQuery } from "../services/apis";
import NoData from "../components/NoData";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

const AllCollection = () => {
  const { data, refetch, isFetching } = useListCategoryQuery();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [noItemsFound, setNoItemsFound] = useState(false);

  useEffect(() => {
    setFilteredItems(data?.data);
  }, [data]);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    filterItems(value);
  };

  const filterItems = (query) => {
    const filtered = data?.data.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredItems(filtered);
    setNoItemsFound(filtered.length === 0);
  };

  return (
    <>
      <Navbar />
      <div className="pt-40 max-w-screen-xl mx-auto px-4">
        <section className="gallery" id="gallery">
          <div className="mb-16 mt-8 text-center">
            <h2 className="text-6xl font-bold text-first bannerText">
              Interests
            </h2>
            <p className="text-2xl mt-6 leading-10 text-gray-700">
              The Artequitys team selects the best of what the platform has to
              offer and arranges them into carefully handpicked collections for
              review and purchase.
            </p>
          </div>
          <div className="flex items-center justify-between w-full gap-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            <div className="text-2xl text-black px-2 py-4 searchBorder flex items-center border">
              <i className="fa-solid fa-magnifying-glass pr-2 text-first"></i>
              <input
                type="text"
                placeholder="Search Interest by Name"
                className="w-full ml-3  focus:outline-none"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="container">
            {isFetching ? (
              <Loader />
            ) : (
              <div className="nft-collections grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-8 ">
                {filteredItems?.length > 0 &&
                  filteredItems.map((item) => (
                    <div
                      key={item?._id}
                      className="swiper-slide box pb-2 bg-white "
                    >
                      <Link to={`/collection/${item?._id}`}>
                        {item?.image && (
                          <img
                            src={item?.image}
                            alt={item?.name}
                            className="w-full h-48 object-cover"
                          />
                        )}
                        <h3 className="text-center text-2xl text-black px-4 py-3 bg-pink-100 ">
                          {item?.name?.length > 25
                            ? item?.name?.slice(0, 25) + "..."
                            : item?.name}
                        </h3>
                      </Link>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </section>
        {filteredItems?.length === 0 && <NoData market />}
      </div>
      <Footer />
    </>
  );
};

export default AllCollection;
