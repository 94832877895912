import { createSlice } from "@reduxjs/toolkit";
import polygon from "../../assets/image/plolygon.png";
import Weth_ABI from "../../utils/web3/WETHABI.json";
import fractionABI from "../../utils/web3/fractionABI.json";
import collectionABI from "../../utils/web3/collectionABI.json";

import {
  fractionAddress,
  collectionAddress,
  WETHTokenAddress,
} from "../../utils/web3/address";

export const getConstants = createSlice({
  name: "constants",
  initialState: {
    loginState: 0,
    walletInfo: {},
    profileData: {},
    avatarInfo: {},
    chainType: {
      id: 1,
      name: "POL",
      blockChain: "Polygon",
      img: polygon,
      wethAbi: Weth_ABI,
      wethAddress: WETHTokenAddress,
      testId: 137,
      mainId: 137,
      collectionAddress: collectionAddress,
      fractionAddress: fractionAddress,
      collectionAbi: collectionABI,
      fractionAbi: fractionABI,
    },
  },
  reducers: {
    setLoginState: (state, action) => {
      state.loginState = action.payload;
    },
    setWalletInfo: (state, action) => {
      state.walletInfo = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setAvatarInfo: (state, action) => {
      state.avatarInfo = action.payload;
    },
    setChainType: (state, action) => {
      state.chainType = action.payload;
    },
  },
});

export const {
  setLoginState,
  setWalletInfo,
  setProfileData,
  setAvatarInfo,
  setChainType,
} = getConstants.actions;

export default getConstants.reducer;
