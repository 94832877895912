import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IMG from "../assets/image/user-dummy.png";
import { useGetProfileQuery, useUserNftsQuery } from "../services/apis";
import moment from "moment";
import { useParams } from "react-router-dom";

const ViewUser = () => {
	const { id } = useParams();

	const { data, refetch } = useGetProfileQuery(id);
	const { data: nftList, refetch: nftRefetch } = useUserNftsQuery(id);

	useEffect(() => {
		refetch();
		nftRefetch();
	}, []);

	return (
		<>
			<Navbar />
			<div className="bg-white mt-56">
				<section className="container">
					<div className="mt-20 flex items-center">
						<img
							src={
								data?.data?.display_picture
									? `${data?.data?.display_picture}`
									: IMG
							}
							alt=""
							className="w-80 h-80 rounded-full shadow-lg"
						/>
						<div className="ml-10 text-left">
							<h3 className="text-6xl font-bold">
								{data?.data?.name || "Unnamed"}
							</h3>
							<p className="text-2xl text-gray-400 mt-3">
								{data?.data?.wallet_address}
							</p>
							<p className="text-2xl text-gray-600 mt-6 font-bold">
								Joined {moment(data?.data?.createdAt).format("DD MMM YYYY")}
							</p>
						</div>
					</div>
				</section>
				<section>
					<h2 className="mt-4 mb-4 text-4xl font-bold">Purchased NFTs</h2>
					<div className="nft-collection">
						{nftList?.data?.length > 0 &&
							nftList?.data?.map((item) => {
								return (
									<div className="shadow-xl rounded-2xl">
										<img src={`${item?.["nft-data"]?.nft_media[0]}`} alt="" />
										<div className="p-6">
											<h2 className="text-3xl font-bold mb-2">
												{item?.["nft-data"]?.nft_name}
											</h2>

											<div className="flex justify-between mt-8">
												<div>
													<h2 className="text-xl font-bold ">Amount Paid</h2>
													<p className="text-xl">{item?.amount} POL</p>
												</div>

												<div>
													<h2 className="text-xl font-bold ">
														Fraction Purchased
													</h2>
													<p className="text-xl">{item?.fraction_amount}</p>
												</div>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default ViewUser;
