import React from "react";
import { Link } from "react-router-dom";
import IMG from "../assets/image/logo.png";

const BannerCard = ({ data, home, isFetching, price }) => {
  const isSold = data?.token_owner?.remaining_fraction === 0;

  return (
    <div className="bg-white shadow">
      <div className="relative">
        {/* Sold Badge */}
        {isSold && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="bg-red-500 text-white font-bold px-4 py-1 rounded">
              SOLD
            </div>
          </div>
        )}

        {/* Image Section */}
        <Link to={`/product/${data?._id}`} state={{ item: data }}>
          <img
            src={isFetching ? IMG : data?.nft_media[0]}
            alt={data?.nft_name || "NFT Image"}
            className={`w-full max-h-[300px] object-cover cursor-pointer ${
              isSold ? "opacity-30" : "opacity-100"
            } ${home ? "h-auto" : ""}`}
          />
        </Link>

        {/* Featured Tag */}
        <span className="absolute top-2 left-2 bg-green-600 text-white px-4 py-1 rounded">
          Featured
        </span>
      </div>

      {/* Details Section */}
      <div className="p-4 text-left bg-pink-100">
        <Link to={`/product/${data?._id}`} state={{ item: data }}>
          <h3 className="text-black text-2xl ">{data?.nft_name}</h3>
        </Link>
        <p className="text-3xl font-bold mt-2">{price} {data?.blockchain}</p>
      </div>
    </div>
  );
};

export default BannerCard;
