import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HomeHeading from "../components/HomeHeading";
import img from "../assets/img2.JPG";
import img2 from "../assets/img3.JPG";
import ImageWithGrid from "../components/ImageWithGrid";

const AuctionHouse = ({ page = false }) => {
  const Grids = ({ title, description, value = "1" }) => {
    return (
      <div className="flex gap-10 w-full items-start">
        <div>
          <span className="h-12 w-12 flex items-center justify-center border-second text-2xl font-bold text-gray-600">
            {value}
          </span>
        </div>
        <p className="mainText text-left leading-10">{description}</p>
      </div>
    );
  };

  const Grid2 = ({ title, description, value = "1" }) => {
    return (
      <div className="flex flex-col items-start ">
        <div className="w-12 h-12  flex items-center justify-center bg-second text-2xl font-bold text-white">
          {value}
        </div>
        {/* <h2 className="font-bold text-5xl mb-4 mt-6 text-black text-left bannerText">
          {title}
        </h2> */}
        <p className="mainText text-left leading-10 mt-6">{description}</p>
      </div>
    );
  };
  return (
    <>
      {page && <Navbar />}
      <div className={`${page && "pt-36"} pb-40`} id="creates">
        <HomeHeading
          title="Auction House"
          description=".
          "
        />

        <div className="flex flex-col lg:flex-row site-container gap-12">
          <div className="">
            <ImageWithGrid imageSrc={img} gridSize={30} />
          </div>
          <div className="flex flex-col gap-12 site-container mx-auto">
            <Grids
              value="1"
              description="THE ARTWORKS WE ACQUIRE to sell through fractionalise NFT Share sale will be auctioned through our Auction House."
            />

            <Grids
              value="2"
              description="WE WILL CURATE EXCLUSIVE ARTWORKS and auction those artworks through our Auction House."
            />

            <Grids
              value="3"
              description="ART HAS THE HIGHEST COMMISSIONS in all asset class."
            />

            <Grids
              value="4"
              description="WE WILL MINT NFT of the art work and auction the artwork through our auction house."
            />

            <Grids
              value="5"
              description="NFTS ARE EMBBEDED ON THE BLOCKCHAIN, This is how the true owner and provenance of the image can be verified and can’t be ever copied or faked."
            />

            <Grids
              value="6"
              description="LOWEST COMMISSION PAYOUT in comparison to sale through the traditional auction houses."
            />

            <Grids
              value="7"
              description="GET ADDITIONAL COMMISSION DISCOUNT upto 40% percent when payment is done through Artequitys NFT Token."
            />
          </div>
        </div>
        <div className="mb-28"></div>
        <HomeHeading
          title="How does it works?"
          description=".
          "
        />
        <div className="flex flex-col lg:flex-row gap-12 site-container">
          <div className="grid grid-cols-2 flex-2 sm:grid-cols-2 gap-12  md:grid-cols-2 xl:grid-cols-3 site-container text-center mx-auto">
            <Grid2
              title="Curated by experts"
              value="1"
              description="Artequitys NFT curates some of the most exclusive Art pieces and assets from galleries and collectors across the world."
            />

            <Grid2
              title="Curated by experts"
              value="2"
              description="All the artworks acquired to sell through fractionalise NFT Share sale will be auctioned through our Auction House."
            />

            <Grid2
              title="Curated by experts"
              value="3"
              description="Once art work is acquired we will mint a NFT of the artwork."
            />

            <Grid2
              title="Curated by experts"
              value="4"
              description="Artwork and NFT will be auctioned through our auction house."
            />

            <Grid2
              title="Curated by experts"
              value="5"
              description="Highest bidder will get the physical artwork and well as the NFT of the artwork."
            />

            <Grid2
              title="Curated by experts"
              value="6"
              description="We will have the lowest commissions in comparison to the traditional auction houses which have levied the buyer's & seller’s commission for several decades, particularly in fine art auctions, with percentages varying from 20–40% percent"
            />
          </div>
          <div className="flex-1">
            <ImageWithGrid imageSrc={img2} gridSize={30} />
          </div>
        </div>
      </div>
      {page && <Footer />}
    </>
  );
};

export default AuctionHouse;
