import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import POLY from "../assets/image/polygon.png";
import BINANCE from "../assets/image/binance.png";
import ETHER from "../assets/image/ethereum.png";
import { useNewsletterMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import DotLoader from "./DotLoader";

const Footer = () => {
	const [email, setEmail] = useState("")
	const [newsletter, { data, isLoading }] = useNewsletterMutation()

	const handleSubscribe = () => {
		if (!email) {
			PopUp("Please enter email", "", "error");
			return
		}
		newsletter({ email })
	}

	useEffect(() => {
		if (data?.success) {
			PopUp("You are subscribed successfully", "", "success")
			return
		}

		if (data?.success === false) {
			PopUp("Email is already subscribed", "", "error")
		}
	}, [data])
	return (
		<>
			<div className="bg-gray-50 w-full py-12">
				<div className="site-container">
					<div className="flex flex-col lg:flex-row gap-6 items-center justify-between">
						<div className="text-gray-600 bannerText text-4xl mb-8 lg:mb-0">
							Supported Chains
						</div>
						<img src={ETHER} alt="" style={{ filter: "brightness(0%)" }} />
						<img src={BINANCE} alt="" style={{ filter: "brightness(0%)" }} />
						<img src={POLY} alt="" style={{ filter: "brightness(0%)" }} />
					</div>
				</div>
			</div>
			<div className="bg-black mx-auto">
				<div className=" flex items-center lg:items-start justify-between mx-auto flex-col md:flex-row site-container">
					<div className="flex-1  py-20 lg:py-40 text-2xl text-white text-center lg:text-left">
						<div style={{ maxWidth: "500px" }}>
							<h2 className="text-4xl font-bold  mb-4 bannerText">
								Stay Connected
							</h2>
							<p>
								Register your email to enroll for our marketing campaigns, and
								to find out more about our latest releases and updates.
							</p>
							<div className="mt-6">
								<input
									type="text"
									className="px-4 py-4 mb-4 text-gray-900 w-full placeholder:text-gray-900"
									placeholder="Enter your email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>{" "}
								<br />
								<button className="bg-first font-bold  py-3 w-full" onClick={handleSubscribe}>
									{isLoading ? <DotLoader /> : "Subscribe"}
								</button>
							</div>
						</div>
					</div>

					<div className="py-20 lg:py-40 text-2xl text-white text-center lg:text-right flex-1">
						<h2 className="text-4xl font-bold bannerText">
							Join Our Community
						</h2>
						<div
							className="flex items-center lg:justify-end gap-6 text-5xl justify-center mt-10"
							style={{ gap: "2em" }}
						>
							<a href="https://www.instagram.com/art_equitys/" target="_blank">
								<i class="fa-brands fa-instagram"></i>
							</a>
							<a href="https://twitter.com/Art_Equitys" target="_blank">
								<i class="fa-brands fa-twitter"></i>
							</a>
							<a href="https://www.facebook.com/art.equitys" target="_blank">
								<i class="fa-brands fa-square-facebook"></i>
							</a>
						</div>
						<div className="mt-20">
							<Link className="mr-10" to="/terms-and-condition">
								Terms and Condition
							</Link>
							<Link className="mr-10" to="/privacy-policy">
								Privacy Policy
							</Link>
							<Link to="/legal">Legal</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full bg-gray-900 py-6 px-20 text-2xl text-white flex items-center justify-center ">
				<div className="copyright flex flex-col text-center lg:flex-row items-center justify-between w-full site-container">
					<h2 className="font-bold text-3xl">artequitys</h2>
					<p>Copyright © 2025 Artequitys. All rights reserved.</p>
				</div>
			</div>
		</>
	);
};

export default Footer;
