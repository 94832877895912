import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { usePurchaseNftMutation } from "../services/apis";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Errors } from "../utils/Errors.js";
import { useNftByIdQuery } from "../services/apis";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import { useSelector } from "react-redux";
import { PopUp } from "../utils/alert";
import { ethers } from "ethers";
import Loader from "../components/Loader";
import Timer from "../components/Timer";
import { TOKEN_NAME, USD } from "../utils/constants";
import WebError from "../components/WebError.jsx";
import ShopInfo from "../components/ShopInfo.jsx";
import NetworkError from "../components/NetworkError.jsx";
import DotLoader from "../components/DotLoader.jsx";
import imgs from "../assets/image/nft1.jpeg";
import useWalletBalances from "../hooks/useWalletBalances.jsx";
import SocialShares from "../components/SocialShares.jsx";

import { Helmet } from "react-helmet";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const profileData = useSelector((state) => state.constants.profileData);
  const walletInfo = useSelector((state) => state.constants.walletInfo);
  const loginState = useSelector((state) => state.constants.loginState);
  const chainType = useSelector((state) => state.constants.chainType);
  const { data, refetch, isFetching, isError } = useNftByIdQuery(id);
  const { coinBalance, tokenBalance } = useWalletBalances(
    chainType?.wethAddress
  );

  const [fractionAmount, setFractionAmount] = useState(false);

  const availItem =
    data?.data?.token_owner?.on_sale_fractions -
    (data?.data?.token_owner?.fraction_amount -
      data?.data?.token_owner?.remaining_fraction);

  const nftPrice = data?.data?.token_owner?.per_fraction_price * fractionAmount;
  const blockchain = data?.data?.blockchain;

  const chainId =
    blockchain === "POL"
      ? 137
      : blockchain === "ETH"
      ? 1
      : blockchain === "BNB"
      ? 56
      : null;

  const [loader, setLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [descShow, setDescShow] = useState(false);
  const [showVideo, setShowVideo] = useState(1);
  const [txnHash, setTxnHash] = useState("");
  const [artShow, setArtShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [typeSwitch, setTypeSwitch] = useState("");
  const [percent, setPercent] = useState(0);

  const [purchaseNft, { data: nftPurchase, isError: purchaseErr }] =
    usePurchaseNftMutation();

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    if (isError) {
      PopUp(
        "Under Maintenance",
        "We are scheduled on maintenance. please check after some time",
        "warning"
      );
    }
  }, [isError]);

  useEffect(() => {
    if (data && data?.data.hasOwnProperty("token_owner") === false) {
      navigate("/");
    }
  }, [data]);

  const purchaseApproval = async () => {
    setLoader(true);
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      let token = new ethers.Contract(
        chainType?.wethAddress,
        chainType?.wethAbi,
        signer
      );

      // Calculate price the same way as in buyWithMatic
      const pricePerFraction = data?.data?.token_owner?.per_fraction_price;
      const priceInWei = ethers.utils.parseEther(pricePerFraction.toString());
      const totalPriceInWei = priceInWei.mul(fractionAmount);

      const estimatedGas = await token.estimateGas.approve(
        chainType?.fractionAddress,
        totalPriceInWei
      );
      // Add 20% buffer to gas estimate
      const gasLimit = estimatedGas.mul(120).div(100);

      let tx = await token.approve(
        chainType?.fractionAddress,
        totalPriceInWei, // Use the same Wei calculation as purchase
        {
          gasLimit: gasLimit, //Eastimated Gas Fee
        }
      );

      const result = await tx.wait();
      if (result?.status === 1) {
        handlePurchase();
      }
    } catch (err) {
      PopUp("User denied transaction", "", "error");
      setLoader(false);
    }
  };

  const handlePurchase = async () => {
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.fractionAddress,
        chainType?.fractionAbi,
        signer
      );

      // Add logging to verify amounts match approval
      const pricePerFraction = data?.data?.token_owner?.per_fraction_price;
      const priceInWei = ethers.utils.parseEther(pricePerFraction.toString());
      const totalPriceInWei = priceInWei.mul(fractionAmount);

      // Check WETH allowance before purchase
      const wethContract = new ethers.Contract(
        chainType?.wethAddress,
        chainType?.wethAbi,
        signer
      );
      const allowance = await wethContract.allowance(
        await signer.getAddress(),
        chainType?.fractionAddress
      );

      const estimatedGas = await token.estimateGas.purchaseFractionWithERC20(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        chainType?.wethAddress
      );
      // Add 20% buffer to gas estimate
      const gasLimit = estimatedGas.mul(120).div(100);

      const purchaseTx = await token.purchaseFractionWithERC20(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        chainType?.wethAddress,
        {
          gasLimit: gasLimit, // Eastimated Gas Price
        }
      );
      const result = await purchaseTx.wait();
      setTxnHash(result.transactionHash);
      if (result?.status === 1) {
        handlePurchaseNft();
      }
    } catch (err) {
      Errors(err);
    } finally {
      setLoader(false);
    }
  };

  const buyWithMatic = async () => {
    setLoader(true);
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      const signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.fractionAddress,
        chainType?.fractionAbi,
        signer
      );
      const pricePerFraction = data?.data?.token_owner?.per_fraction_price;
      // Validation
      if (!pricePerFraction || isNaN(Number(pricePerFraction))) {
        throw new Error("Invalid price per fraction");
      }

      // Calculate prices using BigNumber to maintain precision
      const priceInWei = ethers.utils.parseEther(pricePerFraction.toString());
      const totalPriceInWei = priceInWei.mul(
        ethers.BigNumber.from(fractionAmount)
      );

      // Estimate gas
      const estimatedGas = await token.estimateGas.purchaseFraction(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        {
          value: totalPriceInWei, // Include value in gas estimation
        }
      );

      // Add 20% buffer to gas estimate
      const gasLimit = estimatedGas.mul(120).div(100);

      const tx = await token.purchaseFraction(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        {
          value: totalPriceInWei,
          gasLimit: gasLimit, // Eastimated Gas Price
        }
      );
      const result = await tx.wait();
      setTxnHash(result.transactionHash);
      if (result?.status === 1) {
        handlePurchaseNft();
      }
    } catch (err) {
      Errors(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (nftPurchase?.success) {
      setSuccessShow(true);
      setIsShow(false);
      setFractionAmount("");
      refetch();
    }

    if (nftPurchase?.success === false) {
      PopUp("Something went wrong", "", "error");
    }
  }, [nftPurchase]);

  const handlePurchaseNft = () => {
    purchaseNft({
      token_id: data?.data?._id,
      amount: data?.data?.token_owner?.per_fraction_price * fractionAmount,
      fractionPurchase: Number(fractionAmount),
    });
  };

  useEffect(() => {
    setFractionAmount(
      data?.data?.token_owner?.fixed === 1
        ? data?.data?.token_owner?.amount
        : fractionAmount
    );
  }, [data]);

  const buyNow = (type) => {
    setTypeSwitch(type);

    const showError = (title, description = "", type = "error") => {
      PopUp(title, description, type);
      return false;
    };

    const validations = [
      {
        condition:
          type === "POL" &&
          Number(coinBalance) <
            data?.data?.token_owner?.per_fraction_price * fractionAmount,
        action: () => showError("Insufficient Funds"),
      },
      {
        condition:
          type === "HASHS" &&
          tokenBalance <
            data?.data?.token_owner?.per_fraction_price * fractionAmount,
        action: () => showError("Insufficient Funds"),
      },
      {
        condition: loginState !== 1,
        action: () => {
          showError("Please connect to your wallet");
          navigate("/");
        },
      },
      {
        condition: isError,
        action: () =>
          showError(
            "Under Maintenance",
            "We are currently undergoing maintenance. Please check back later.",
            "warning"
          ),
      },
      {
        condition: profileData?.data?.is_verified === 0,
        action: () =>
          showError(
            "Please verify your email first",
            "Verify your email in the My Profile section"
          ),
      },
      {
        condition: !fractionAmount,
        action: () => showError("Please enter item amount"),
      },
      {
        condition: fractionAmount < 5 && data?.data?.token_owner?.fixed === 0,
        action: () => showError("Fraction amount should not be less than 5"),
      },

      {
        condition: fractionAmount > availItem,
        action: () =>
          showError(
            "Not enough Items",
            `Only ${availItem} Items are available`
          ),
      },
    ];
    for (const { condition, action } of validations) {
      if (condition) return action();
    }
    setIsShow(true);
  };

  useEffect(() => {
    if (percent) {
      const value = (availItem / 100) * percent;
      setFractionAmount(Math.round(value));
    }
  }, [percent]);

  return (
    <>
      <Navbar />
      {/* <Helmet>
        <title>{data?.data?.nft_name} | NFT Marketplace</title>
        <meta name="description" content={data?.data?.description} />
        <meta property="og:title" content={data?.data?.nft_name} />
        <meta property="og:description" content={data?.data?.description} />
        <meta property="og:image" content={data?.data?.nft_media[0]} />
        <meta property="og:url" content={window.location.href} />
      </Helmet> */}

      <section className="gallery mt-2 relative" id="gallery">
        <div className="product-container">
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <div
                className="flex productDetail"
                style={{
                  maxWidth: "1200px",
                  width: "95%",
                  margin: "0 auto",
                  gap: "3em",
                }}
              >
                <div className="flex-1">
                  {data?.data?.video && (
                    <div
                      className="flex gap-7 bg-white text-2xl py-2 rounded-lg"
                      style={{ width: "max-content" }}
                    >
                      <h4
                        onClick={() => setShowVideo(1)}
                        className={`${
                          showVideo === 1
                            ? "bg-first text-white px-4 py-1"
                            : "py-1"
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <i class="fa-solid fa-image"></i>
                      </h4>
                      <h4
                        className={`${
                          showVideo === 2
                            ? "bg-second text-white px-4 py-1"
                            : "py-1"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowVideo(2)}
                      >
                        <i class="fa-solid fa-video"></i>
                      </h4>
                    </div>
                  )}
                  {showVideo === 1 && (
                    <div
                      style={{
                        height: "100%",
                        maxHeight: "75vh",
                        width: "100%",
                      }}
                    >
                      <img
                        alt={data?.data?.nft_name}
                        src={data?.data?.nft_media[0]}
                        className="h-full w-full object-cover  cursor-pointer"
                        onClick={() => setArtShow(true)}
                      />
                      <div className="mainBorder text-gray-500 bg-opacity-20 px-4 flex items-center justify-between  text-xl">
                        <p>Click on image to view original size</p>
                        <div>
                          <a href={data?.data?.external_link} target="_new">
                            Link{" "}
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {showVideo === 2 && (
                    <div>
                      <video src={data?.data?.video} controls auto></video>
                    </div>
                  )}
                </div>
                <div className=" flex-1 md:mt-0">
                  <h1
                    className="text-5xl font-bold mt-10 md:mt-0 mb-10 bannerText"
                    style={{ lineHeight: "1.3em" }}
                  >
                    {data?.data?.nft_name}
                  </h1>
                  <ShopInfo item={data} />
                  <div
                    className=" mainBorder mt-10 bg-white bg-opacity-10"
                    onClick={() => setDescShow((prev) => !prev)}
                  >
                    <div
                      className="flex items-center justify-between m-2 p-3"
                      style={{
                        borderBottom:
                          descShow && "1px solid rgb(255, 255, 255, 0.1)",
                      }}
                    >
                      <h4 className="text-2xl font-bold text-gray-700 ">
                        <i class="fa-solid fa-align-center mr-4"></i>
                        Description
                      </h4>
                      <span className="cursor-pointer text-2xl text-first">
                        {descShow ? (
                          <i class="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i class="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>
                    {descShow && (
                      <p
                        style={{
                          padding: "15px",
                          fontSize: "15px",
                        }}
                        className="overflow-auto max-h-72 text-first"
                      >
                        {data?.data?.description}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 mainBorder ">
                    <h4
                      className="text-2xl font-bold text-gray-700 mb-2 mt-2 p-4"
                      style={{
                        borderBottom: "0.5px solid rgb(0, 0, 0, 0.1)",
                      }}
                    >
                      <i class="fa-solid fa-sack-dollar mr-4"></i>
                      CURRENT PRICE
                    </h4>
                    <div className="p-6">
                      <h3 className="text-5xl font-bold text-black mb-1">
                        {parseFloat(
                          data?.data?.token_owner?.price.toFixed(3)
                        ).toString()}{" "}
                        {blockchain}
                      </h3>
                      {data?.data?.token_owner?.fixed === 0 && (
                        <div className="flex gap-6 items-center mt-4">
                          <span className="text-lg font-bold  px-2 py-1 text-gray-900 bg-pink-50">
                            {(
                              data?.data?.token_owner?.price /
                              data?.data?.token_owner?.amount
                            ).toFixed(4)}{" "}
                            {blockchain} Per Fraction
                          </span>{" "}
                          <span className="text-lg font-bold text-gray-600 bg-green-50 px-3 py-1">
                            {(USD * data?.data?.token_owner?.price).toFixed(3)}{" "}
                            HASHS
                          </span>
                          <span className="px-3 py-1 text-lg font-bold text-gray-600 bg-gray-100">
                            {availItem} Fractions Left
                          </span>
                        </div>
                      )}
                    </div>

                    {data?.data?.token_owner?.on_sale_fractions -
                      (data?.data?.token_owner?.fraction_amount -
                        data?.data?.token_owner?.remaining_fraction) ===
                    0 ? (
                      <div className="bg-red-300 flex items-center justify-center p-6 font-bold m-6 rounded text-3xl text-red-700">
                        NFT SOLD
                      </div>
                    ) : (
                      <>
                        <div className="flex items-center mb-3 mt-2 mx-6">
                          {data?.data?.token_owner?.fixed === 0 && (
                            <>
                              <div className="flex items-center w-full gap-2">
                                <button
                                  onClick={() =>
                                    fractionAmount > 0 &&
                                    setFractionAmount((prev) => prev - 1)
                                  }
                                  className="bg-pink-50 mainBorder font-bold text-4xl py-2.5 px-5"
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  className="mainBorder w-full p-3 text-2xl"
                                  min="0"
                                  placeholder="Enter Fraction Value"
                                  value={fractionAmount}
                                  disabled={
                                    data?.data?.token_owner
                                      ?.remaining_fraction === 0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    setFractionAmount(e.target.value)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    setFractionAmount((prev) => prev + 1)
                                  }
                                  className="bg-green-50 mainBorder font-bold text-4xl py-2.5 px-5"
                                >
                                  +
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        {profileData?.data?.role === "user" &&
                          data?.data?.token_owner?.fixed === 0 && (
                            <div className="px-6 mb-8 flex gap-5 text-first mt-4">
                              {[10, 25, 50, 100].map((item) => {
                                return (
                                  <span
                                    onClick={() => setPercent(item)}
                                    className="px-4 py-2 mainBorder cursor-pointer txt2"
                                  >
                                    {item}%
                                  </span>
                                );
                              })}
                              {fractionAmount && (
                                <div className="mainBorder px-4 py-2 flex items-center gap-4">
                                  <div className="font-bold">Final Price:</div>
                                  <div className="flex gap-4">
                                    <span>
                                      {(
                                        data?.data?.token_owner
                                          ?.per_fraction_price * fractionAmount
                                      ).toFixed(3)}{" "}
                                      {blockchain}
                                    </span>
                                    <div className="w-1 h-6 bg-gray-200"></div>
                                    <span>
                                      {(
                                        data?.data?.token_owner
                                          ?.per_fraction_price *
                                        fractionAmount *
                                        USD
                                      ).toFixed(3)}{" "}
                                      HASHS
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                        <div>
                          {profileData?.data?.role === "user" ? (
                            <div className="flex gap-6 mx-6 mb-8">
                              <button
                                className="text-2xl bg-second font-bold px-16 py-6 text-white flex-1"
                                onClick={() =>
                                  walletInfo?.chainId !== chainId
                                    ? PopUp(
                                        "Please use a valid network",
                                        "",
                                        "error"
                                      )
                                    : buyNow("POL")
                                }
                              >
                                {blockchain} Buy
                              </button>
                              <button
                                className="text-2xl bg-first font-bold px-16 py-6 text-white flex-1"
                                onClick={() =>
                                  walletInfo?.chainId !== chainId
                                    ? PopUp(
                                        "Please use a valid network",
                                        "",
                                        "error"
                                      )
                                    : buyNow("HASHS")
                                }
                              >
                                {TOKEN_NAME} Buy
                              </button>
                            </div>
                          ) : (
                            <div className="flex gap-6 mx-6 mb-8">
                              <button className="text-2xl bg-first font-bold px-16 py-6 text-white flex-1">
                                Connect as a USER to Buy NFT
                              </button>
                            </div>
                          )}

                          {data?.data?.token_owner?.on_sale_fractions -
                            (data?.data?.token_owner?.fraction_amount -
                              data?.data?.token_owner?.remaining_fraction) !==
                            0 &&
                            profileData?.data?.role === "user" && (
                              <div className="mx-6 mb-8 text-2xl text-gray-600 ">
                                Buy with {TOKEN_NAME} token and get{" "}
                                <span className="text-yellow-500 font-bold">
                                  Commission Discount
                                </span>{" "}
                                on current price.
                              </div>
                            )}
                        </div>
                      </>
                    )}
                  </div>

                  <div>
                    <SocialShares
                      url={window.location.href}
                      title={`Check out this NFT: ${data?.data?.nft_name}`}
                      image={data?.data?.nft_media[0]}
                    />
                  </div>

                  <br />

                  {data?.data?.token_owner?.auction === 1 && (
                    <Timer dateVal={data?.data?.token_owner?.auction_date} />
                  )}
                </div>
              </div>
              {profileData?.data?.role === "user" && chainId !== null &&
                walletInfo?.chainId !== chainId && (
                  <NetworkError network={blockchain} id={chainId} />
                )}
            </>
          )}
        </div>
      </section>

      <Footer />

      <Modal show={isShow} onClose={() => setIsShow(false)} title="CHECKOUT">
        <div className="flex items-center justify-center flex-col">
          <div style={{ width: "100%" }}>
            <p className="text-2xl mt-12 text-center mb-12">
              Check you detail carefully. Once purchased can not be reversed.
            </p>
            {typeSwitch === "HASHS" && (
              <div className="text-center font-bold mb-8 bg-pink-100 rounded py-6">
                <h2 className="text-4xl text-pink-600"> Congratulation</h2>
                <p className="text-2xl font-bold text-center">
                  You got 20% discount!
                </p>
              </div>
            )}
            <div className="bg-gray-50 p-6 mb-8">
              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">NFT NAME</h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.nft_name}
                </p>
              </div>

              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">NFT ID</h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.token_id}
                </p>
              </div>

              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">
                  BUY FRACTION
                </h3>
                <p className="text-xl text-center mb-4">
                  {fractionAmount} Items
                </p>
              </div>

              <div className="flex items-start justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">
                  NFT PRICE
                </h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.token_owner?.per_fraction_price * fractionAmount}{" "}
                  {blockchain}
                </p>
              </div>

              {typeSwitch === "HASHS" && (
                <>
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-xl font-bold text-center mb-4">
                      DISCOUNT
                    </h3>
                    <p className="text-xl text-red-700 text-center mb-4">
                      - 20%
                    </p>
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-xl font-bold text-center mb-4">
                      FINAL PRICE
                    </h3>
                    <p className="text-xl text-green-600 text-center mb-4">
                      {(nftPrice * 20) / 100}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6 mt-6">
              <button
                className="profile-btn2 flex-1"
                style={{ fontSize: "14px" }}
                onClick={() => setIsShow(false)}
              >
                Cancel
              </button>
              <button
                className="profile-btn flex-1"
                style={{ fontSize: "14px" }}
                onClick={() => {
                  if (typeSwitch !== "") {
                    if (typeSwitch === "POL") {
                      buyWithMatic();
                    } else {
                      purchaseApproval();
                    }
                  }
                }}
                disabled={loader}
              >
                {loader ? <DotLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={artShow} isScale onClose={() => setArtShow(false)}>
        <img
          src={
            data?.data?._id === "651043e56458efb78754ba24"
              ? imgs
              : `${data?.data?.nft_media[0]}`
          }
          alt=""
          className="object-contain w-full"
          style={{ height: "90%" }}
        />
      </Modal>
      <Modal show={successShow} onClose={() => setSuccessShow(false)}>
        <div className="py-12">
          <div className="flex items-center justify-center flex-col">
            <i class="fa-solid fa-circle-check text-7xl text-first"></i>
            <h2 className="text-5xl font-bold text-gray-600 mt-6">
              Order Completed
            </h2>
          </div>
          <div>
            <p className="text-center mt-12 text-2xl mx-8">
              You have successfully purchased{" "}
              <span className="font-bold text-pink-600">
                {data?.data?.nft_name}
              </span>{" "}
              from Artequitys
            </p>
          </div>
          <div className="bg-gray-100 rounded mt-20 p-8 flex items-center justify-between mx-8 mb-4">
            <div>
              <h3 className="font-bold text-2xl mb-2">Status</h3>
              <span className="text-green-600 text-xl">Completed</span>
            </div>
            <div>
              <h3 className="font-bold text-2xl mb-2">Transaction ID</h3>
              <span className="text-gray-600 text-xl ">
                {txnHash.slice(0, 10) + "..." + txnHash.slice(-5) ||
                  "Not Available"}
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={purchaseErr}>
        <WebError id={txnHash} />
      </Modal>
    </>
  );
};

export default Product;
