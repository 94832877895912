import React, { useRef, useState, useEffect } from 'react';

const ImageWithGrid = ({ imageSrc, gridSize = 20 }) => {
  const imageRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  // When the image loads, set the image's natural dimensions
  useEffect(() => {
    if (imageRef.current) {
      setImageDimensions({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
    }
  }, [imageSrc]);

  // Calculate the size of each grid cell based on the image dimensions
  const gridCellWidth = imageDimensions.width / gridSize;
  const gridCellHeight = imageDimensions.height / gridSize;

  return (
    <div className="relative w-full h-auto">
      {/* Image element */}
      <img
        ref={imageRef}
        src={imageSrc}
        alt="Image with grid"
        className="w-full h-full  object-cover" // This ensures the image scales correctly while maintaining aspect ratio
        style={{ imageRendering: 'pixelated' }} // Optional: to enhance pixelation effect
      />

      {/* Grid overlay */}
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        style={{
          backgroundSize: `${gridCellWidth}px ${gridCellHeight}px`, // Set the size of each grid cell
          backgroundImage:
            'linear-gradient(to right, #888 0.5px, transparent 0.5px), linear-gradient(to bottom, #888 0.5px, transparent 0.5px)',
          backgroundPosition: 'top left', // Align the background grid to the top left
        }}
      ></div>
    </div>
  );
};

export default ImageWithGrid;
